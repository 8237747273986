*, :after, :before {
  box-sizing: border-box;
  font-family: 'DINNextW05';
}

body {
  margin: 0;
}

@font-face {
  font-family: "DINNextW05";
  src: url("assets/fonts/DINNextW05-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "DINNextW05";
  src: url("assets/fonts/DINNextW05-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Valorant";
  src: url("assets/fonts/Valorant.ttf") format("truetype");
}

@font-face {
  font-family: "Tungsten";
  src: url("assets/fonts/Tungsten-Bold.ttf") format("truetype");
  font-weight: 600;
}